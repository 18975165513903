import React, { useRef } from "react";
import { useSnackbar } from "notistack";

// Mui
import Button from "@mui/material/Button";

// Interfaces
import { Participant } from "../../../../../interfaces/Participant";

// Custom components
import Editor from "../../../../general/ultraEditor";

// Utils
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Firebase
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../../firebase/firebase";

interface Props {
    participant: Participant | null;
}

const Logbook: React.FC<Props> = ({ participant = null }) => {
    const editorRef = useRef<any>(null);
    const { setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    const handleSaveNotes = async () => {
        try {
            setLoading(true);
            if (editorRef.current === null) {
                console.error("editor ref has not been set");
                return;
            }
            if (participant && participant.id) {
                await updateDoc(doc(firestore, "Participants", participant.id), {
                    notes: await editorRef.current.getRawContent(),
                });

                enqueueSnackbar("Modified notes", { variant: "success" });
            }
        } catch (e: any) {
            if (e.response && e.response.data) enqueueSnackbar(e.response.data, { variant: "error" });
            else console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Editor ref={editorRef} rawContent={participant?.notes} />

            <div style={{ display: "flex" }}>
                <Button variant="contained" color="primary" style={{ marginLeft: "auto", marginTop: 20 }} onClick={() => handleSaveNotes()}>
                    Update
                </Button>
            </div>
        </>
    );
};

export default Logbook;
