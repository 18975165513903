import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// Assets
import healthLogo from "../../../assets/images/logos/healthLogo.png";

// Icons
import { IoChevronBack } from "react-icons/io5";
import { RiFileList2Line } from "react-icons/ri";

// Custom component
import TopMenu from "../../general/TopMenu";
import MediaCard from "../../general/MediaCard";
import FileCard from "../../general/FileCard";
import BiometricDialog from "../../general/BiometricDialog";
import SurveyDialog from "./questionnaires/SurveyDialog";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../utils/firebase";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Interfaces
import { File } from "../../../interfaces/File";

// Utils
import { getIcon } from "../../../utils/general";
import MyGoals from "../../general/MyGoals";

const Health: React.FC = () => {
    const hist = useHistory();
    const { currentParticipant, setLoading } = useFetchUser();

    // States
    const [surveyDialogOpen, setSurveyDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<File[]>([]);
    const [files, setFiles] = useState<File[]>([]);

    const fetchHealthContent = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.health.content.length) {
            const localContent: File[] = [];
            for (const c of currentParticipant.health.content) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localContent.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) {
                        const privateContent = genDoc<File>()(payload);
                        privateContent.participantId = currentParticipant.id;
                        localContent.push(privateContent);
                    }
                }
            }
            setContent(localContent);
        }
    };

    const fetchHealthFiles = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.health.files.length) {
            const localFiles: File[] = [];
            for (const c of currentParticipant.health.files) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localFiles.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) localFiles.push(genDoc<File>()(payload));
                }
            }
            setFiles(localFiles);
        }
    };

    const loadData = async () => {
        if (currentParticipant) {
            await fetchHealthContent();
            await fetchHealthFiles();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentParticipant) {
            loadData();
        }
    }, [currentParticipant]);

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: "Visited health page" }],
            });
        }
    }, []);

    return (
        <>
            <TopMenu title="Your heart health" icon={healthLogo} backButton />

            <div className="health">
                <div>
                    <div>
                        <h4 className="home__title">Interactive content</h4>
                        <Grid container item>
                            {content.map((c, i) => (
                                <Grid key={i} item xs={4}>
                                    <MediaCard
                                        title={c.title}
                                        onClick={async () => {
                                            if (currentParticipant && currentParticipant.id) {
                                                // Private content
                                                if (c.participantId) {
                                                    hist.push(`videoViewer/${c.id}/${currentParticipant.id}`);
                                                }
                                                // Shared content
                                                else {
                                                    hist.push(`videoViewer/${c.id}/undefined`);
                                                }
                                                // Participant log
                                                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                    logs: [
                                                        ...(currentParticipant?.logs ?? []),
                                                        { date: new Date(), action: `Viewed health video ${c.title} (${c.id})` },
                                                    ],
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
                <div>
                    <h4 className="home__title">Personalized Instructions</h4>
                    <div className="health__files">
                        {files.map((f, i) => (
                            <FileCard
                                key={i}
                                title={f.title}
                                icon={getIcon(f.extension)}
                                onClick={async () => {
                                    if (currentParticipant && currentParticipant.id) {
                                        // Participant log
                                        await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                            logs: [
                                                ...(currentParticipant?.logs ?? []),
                                                { date: new Date(), action: `Viewed health file ${f.title} (${f.id})` },
                                            ],
                                        });
                                    }
                                    window.open(f.url, "_blank");
                                }}
                            />
                        ))}
                    </div>
                </div>
                {currentParticipant && currentParticipant.hasBiometricData ? (
                    <div>
                        <h4 className="home__title">Biometric data</h4>
                        <div>
                            <Button fullWidth className="cta__blue" variant="contained" onClick={() => setOpen(true)}>
                                Update my data <span className="ct__neuropeak__btnArrow">→</span>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <MyGoals domain="health" />
                <div>
                    <Grid item md={6} className="home__optionsContainer">
                        <Grid item xs={12} onClick={() => hist.push("/")} className="home__options__card">
                            <h4 className="home__options__title">Back to Home</h4>
                            <IoChevronBack className="home__options__image" />
                            <IoChevronBack className="home__options__background" />
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} className="home__options__card" onClick={() => setSurveyDialogOpen(true)}>
                            <Button className="home__options__title">
                                <h4>Complete survey</h4>
                            </Button>
                            <RiFileList2Line className="home__options__image" />
                            <RiFileList2Line className="home__options__background" />
                        </Grid>
                    </Grid>
                </div>
            </div>
            {open && <BiometricDialog open={open} setOpen={setOpen} />}
            {surveyDialogOpen && <SurveyDialog open={surveyDialogOpen} setOpen={setSurveyDialogOpen} topic="Health" />}
        </>
    );
};

export default Health;
