import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

// Mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Assets
import dietEatingPattern from "../../../../assets/images/questionnaires/diet_eating_pattern.png";
import dietHistory from "../../../../assets/images/questionnaires/diet_history.png";
import borgScale from "../../../../assets/images/questionnaires/borg_scale.png";

// Icons
import { FaGreaterThan, FaLessThan } from "react-icons/fa";

// Custom components
import InstructionDialog from "./InstructionDialog";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & {
//         children: React.ReactElement<any, any>;
//     },
//     ref: React.Ref<unknown>
// ) {
//     return <Slide direction="down" ref={ref} {...props} />;
// });

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
const checkboxSoloAnswers = ["None of the above", "NONE"];

interface AnswerComponentProps {
    formData: any;
    type: string;
    data: any;
    // eslint-disable-next-line
    setData: (data: any) => void;
    // eslint-disable-next-line
    setAnswer: (answer: any) => void;
    answers: number[];
    step: number;
}

const AnswerComponent: React.FC<AnswerComponentProps> = ({ formData, type, data, setData, setAnswer, answers, step }) => {
    // States
    const [componentStates, setComponentStates] = useState<any>(null);

    // Reset fields
    useEffect(() => {
        if (type === "mixed" && !data) {
            formData.questions[step].textfields.map((t: string) => {
                const element: any = document.getElementById(t);
                if (element) element.value = "";
            });

            formData.questions[step].answers.map((a: string) => {
                const element: any = document.getElementById(a);
                if (element) element.checked = false;
            });
        }
        if (type === "mixed" && data) {
            if (typeof data === "string") {
                formData.questions[step].textfields.map((t: string) => {
                    const element: any = document.getElementById(t);
                    if (element) {
                        element.value = "";
                    }
                });
            } else {
                formData.questions[step].answers.map((a: string) => {
                    const element: any = document.getElementById(a);
                    if (element) {
                        element.checked = false;
                    }
                });
            }
        }
    }, [data]);

    // Initializing data array
    useEffect(() => {
        if (type === "checkbox") {
            const initialState: any = {};

            if (!componentStates) {
                formData.questions[step].answers.forEach((a: string) => {
                    initialState[a] = false;
                });

                setComponentStates(initialState);
            }
        }
    }, [type]);

    if (type === "radio") {
        return (
            <>
                {formData.questions[step] && (
                    <RadioGroup className="question__radioContainer" row value={data} onChange={e => setData(e.target.value)}>
                        {formData.questions[step].answers
                            .filter((answer: string, i: number) => {
                                /* EATING PATTERN LOGIC */
                                if (formData.title === "Eating Pattern Self Assessment") {
                                    if (formData.questions[step].questionNumber === "1a") {
                                        const maxWeeklyValue = formData.questions[0].weeklyValues[answers[0] - 1];

                                        if (typeof maxWeeklyValue !== "undefined") {
                                            if (formData.questions[step].weeklyValues[i] <= maxWeeklyValue) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    }
                                    if (formData.questions[step].questionNumber === "1b") {
                                        const maxWeeklyValue = formData.questions[0].weeklyValues[answers[0] - 1];
                                        const oneAWeeklyValue = formData.questions[1].weeklyValues[answers[1] - 1];

                                        if (typeof maxWeeklyValue !== "undefined" && typeof oneAWeeklyValue !== "undefined") {
                                            if (formData.questions[step].weeklyValues[i] + oneAWeeklyValue <= maxWeeklyValue) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    }
                                    if (formData.questions[step].questionNumber === "2a") {
                                        const maxWeeklyValue = formData.questions[3].weeklyValues[answers[3] - 1];

                                        if (typeof maxWeeklyValue !== "undefined") {
                                            if (formData.questions[step].weeklyValues[i] <= maxWeeklyValue) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    }
                                    if (formData.questions[step].questionNumber === "3a") {
                                        const maxWeeklyValue = formData.questions[5].weeklyValues[answers[5] - 1];

                                        if (typeof maxWeeklyValue !== "undefined") {
                                            if (formData.questions[step].weeklyValues[i] <= maxWeeklyValue) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    }
                                    if (formData.questions[step].questionNumber === "4a") {
                                        const maxWeeklyValue = formData.questions[7].weeklyValues[answers[7] - 1];

                                        if (typeof maxWeeklyValue !== "undefined") {
                                            if (formData.questions[step].weeklyValues[i] <= maxWeeklyValue) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    }
                                    if (formData.questions[step].questionNumber === "9a") {
                                        const maxWeeklyValue = formData.questions[14].weeklyValues[answers[14] - 1];

                                        if (typeof maxWeeklyValue !== "undefined") {
                                            if (formData.questions[step].weeklyValues[i] <= maxWeeklyValue) {
                                                return true;
                                            }
                                            return false;
                                        }
                                    }
                                }

                                return true;
                                /* end of EATING PATTERN LOGIC */
                            })
                            .map((a: string, i: number) => (
                                <p key={i}>
                                    <FormControlLabel
                                        key={i}
                                        value={a}
                                        control={<Radio className="question__radio" onClick={() => setAnswer(i + 1)} />}
                                        label={a}
                                    />
                                </p>
                            ))}
                    </RadioGroup>
                )}
            </>
        );
    } else if (type === "slider") {
        return (
            <div className="forms__slider">
                <Slider
                    style={
                        formData.questions[step].answers.findIndex((a: string) => a === data) === -1
                            ? { opacity: 0.3, color: "gray" }
                            : { opacity: 1 }
                    }
                    defaultValue={0}
                    step={1}
                    value={formData.questions[step].answers.findIndex((a: string) => a === data)}
                    max={formData.questions[step].answers.length - 1}
                    onChange={(_, newValue) => {
                        setData(formData.questions[step].answers[typeof newValue === "number" ? newValue : newValue[0]]);
                        setAnswer(newValue);
                    }}
                    marks={formData.questions[step].answers.map((a: string, i: number) => {
                        return {
                            value: i,
                            label: a,
                        };
                    })}
                />
            </div>
        );
    } else if (type === "date") {
        return (
            <div className="forms__date">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        openTo="year"
                        disableFuture
                        views={["day"]}
                        // label="Custom date"
                        inputFormat="DD/MM/YYYY"
                        value={data}
                        onChange={value => {
                            setData(value.toDate());
                            setAnswer(value);
                        }}
                        renderInput={(params: any) => <TextField fullWidth variant="outlined" required style={{ marginBottom: 10 }} {...params} />}
                    />
                </LocalizationProvider>
            </div>
        );
    } else if (type === "checkbox" && componentStates) {
        return (
            <>
                {formData.questions[step].answers.map((a: string, i: number) => (
                    <FormControlLabel
                        key={i}
                        id={a}
                        checked={Object.entries(componentStates).some((e: any) => e[0] === a && e[1])}
                        control={<Checkbox />}
                        label={a}
                        labelPlacement="end"
                        onChange={(_, checked) => {
                            // uncheck all other options if checkboxSoloAnswer is selected
                            if (checkboxSoloAnswers.includes(a) && checked) {
                                const initialStates: any = {};
                                formData.questions[step].answers.forEach((a: string) => {
                                    if (!checkboxSoloAnswers.includes(a)) initialStates[a] = false;
                                    else initialStates[a] = true;
                                });

                                setComponentStates(initialStates);
                                setData([a]);
                            } else if (checked) {
                                const localStates = componentStates;

                                setAnswer(1);

                                // uncheck checkboxSoloAnswers if other is selected
                                if (!checkboxSoloAnswers.includes(a)) {
                                    formData.questions[step].answers.forEach((a: string) => {
                                        if (checkboxSoloAnswers.includes(a) && localStates[a]) localStates[a] = false;
                                    });
                                }

                                localStates[a] = true;
                                setComponentStates(localStates);
                                setData([...data, a]);
                            } else {
                                componentStates[a] = false;
                                setComponentStates(componentStates);
                                setData(data.filter((d: string) => d !== a));
                            }
                        }}
                    />
                ))}
            </>
        );
    } else if (type === "textfield") {
        return <TextField variant="outlined" multiline rows={5} label="" value={data} onChange={e => setData(e.target.value)} fullWidth />;
    } else if (type === "textfield-singleline") {
        return (
            <Grid item container>
                <Grid item xs={10}>
                    <TextField variant="standard" label="" value={data} onChange={e => setData(e.target.value)} fullWidth />
                </Grid>
                <Grid item xs={2}>
                    <p>{formData.questions[step].textfields[0]}</p>
                </Grid>
            </Grid>
        );
    } else if (type === "mixed") {
        return (
            <>
                {formData.questions[step].textfields.map((t: string, i: number) => (
                    <TextField
                        key={i}
                        id={t}
                        variant="standard"
                        label={t}
                        value={data[t]}
                        onChange={e => {
                            let localData = data;
                            if (typeof localData === "string") localData = {};
                            setData({ ...localData, [t]: e.target.value });
                        }}
                        fullWidth
                    />
                ))}

                <div style={{ paddingTop: 20 }} />

                {formData.questions[step].answers.map((a: string, i: number) => (
                    <FormControlLabel
                        key={i}
                        id={a}
                        control={<Checkbox />}
                        label={a}
                        value={data}
                        labelPlacement="end"
                        onChange={(e, checked) => {
                            if (checked) setData(a);
                            else setData("");
                        }}
                    />
                ))}
            </>
        );
    } else return <></>;
};

interface QuestionProps {
    formData: any;
    // eslint-disable-next-line
    onComplete: (results: any, answers: number[]) => void;
    // eslint-disable-next-line
    setOpen: (value: boolean) => void;
    participantId?: string;
    answers: number[];
    setAnswers: React.Dispatch<React.SetStateAction<number[]>>;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    results: any[];
    setResults: React.Dispatch<React.SetStateAction<any[]>>;
}

const Question: React.FC<QuestionProps> = ({ formData, onComplete, participantId, answers, setAnswers, step, setStep, results, setResults }) => {
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { setLoading } = useFetchUser();

    // States
    const [dialogOpen, setDialogOpen] = useState(false);
    const [resetDialogOpen, setResetDialogOpen] = useState(false);

    const [selectedTypes] = useState<string[]>([]);
    const [data, setData] = useState<any>("");
    const [answer, setAnswer] = useState<number>(0);
    const [isLargeImage, setIsLargeImage] = useState(false);

    const handleReset = () => {
        setData("");
        setAnswer(0);
        setResults([]);
        setAnswers([]);
        setStep(0);
    };

    const handleResetDialogOpen = () => {
        setResetDialogOpen(true);
    };

    const handleResetDialogClose = () => {
        setResetDialogOpen(false);
    };

    const handleConfirmReset = () => {
        handleReset();
        handleResetDialogClose();
    };

    const handleData = () => {
        let localAnswers = undefined;

        if (answer) {
            if (answers[step]) {
                answers[step] = answer;
                localAnswers = answers;
                setAnswers(answers);
            } else {
                localAnswers = [...answers, answer];
                setAnswers([...answers, answer]);
            }
        }

        if (data) {
            // Already in array
            if (results.some(r => Object.keys(r)[0].includes(`question${formData.questions[step].questionNumber}`))) {
                const index = results.findIndex(r => Object.keys(r)[0].includes(`question${formData.questions[step].questionNumber}`));

                if (index !== -1) {
                    const localResults: any[] = results;

                    localResults[index] = { [`question${formData.questions[step].questionNumber}`]: data };

                    setResults(localResults);

                    if (step >= formData.questions.length - 1) handleSubmit(localResults, localAnswers);
                    else handleNext(localResults);
                }
            }
            // Not in array
            else {
                const localResults = [...results, { [`question${formData.questions[step].questionNumber}`]: data }];

                setResults([...results, { [`question${formData.questions[step].questionNumber}`]: data }]);

                if (step >= formData.questions.length - 1)
                    handleSubmit([...results, { [`question${formData.questions[step].questionNumber}`]: data }], localAnswers);
                else handleNext(localResults);
            }
        }
        // textfield questions are optional
        else if (!data && formData.questions[step].type === "textfield" && formData.questions[step].optional) {
            if (step >= formData.questions.length - 1) {
                handleSubmit(results, localAnswers);
            } else {
                handleNext(results);
            }
        } else enqueueSnackbar("Please answer the question", { variant: "error" });
    };

    const isNonSkipTrigger = (localResults: any[], questionIndex: number) => {
        const localAnswers: any = Object.values(localResults[localResults.length - 1])[0];

        if (formData.questions[questionIndex].nonSkipTrigger) {
            if (typeof formData.questions[questionIndex].nonSkipTrigger === "string") {
                if (typeof localAnswers === "string") {
                    return localAnswers === formData.questions[questionIndex].nonSkipTrigger;
                } else {
                    return localAnswers.some((x: any) => x === formData.questions[questionIndex].nonSkipTrigger);
                }
            } else {
                if (typeof localAnswers === "string") {
                    return formData.questions[questionIndex].nonSkipTrigger.some((x: string) => x === localAnswers);
                } else {
                    return formData.questions[questionIndex].nonSkipTrigger.some((x: string) => localAnswers.some((y: any) => x === y));
                }
            }
        }

        return false;
    };

    const isTrigger = (localResults: any[], questionIndex: number) => {
        const localAnswers: any = Object.values(localResults[localResults.length - 1])[0];

        // Always skip on null
        if (formData.questions[questionIndex].skipTrigger === null) return true;

        if (formData.questions[questionIndex].skipTrigger) {
            if (!isNonSkipTrigger(localResults, questionIndex)) {
                if (typeof formData.questions[questionIndex].skipTrigger === "string") {
                    if (typeof localAnswers === "string") {
                        return localAnswers === formData.questions[questionIndex].skipTrigger;
                    } else {
                        return localAnswers.some((x: any) => x === formData.questions[questionIndex].skipTrigger);
                    }
                } else {
                    if (typeof localAnswers === "string") {
                        return formData.questions[questionIndex].skipTrigger.some((x: string) => x === localAnswers);
                    } else {
                        return formData.questions[questionIndex].skipTrigger.some((x: string) => localAnswers.some((y: any) => x === y));
                    }
                }
            }
        }
        return false;
    };

    const isEndTrigger = (localResults: any[], questionIndex: number) => {
        const localAnswers: any = Object.values(localResults[localResults.length - 1])[0];

        // Always skip on null
        if (formData.questions[questionIndex].endTrigger === null) return true;

        if (formData.questions[questionIndex].endTrigger) {
            if (!isNonSkipTrigger(localResults, questionIndex)) {
                if (typeof formData.questions[questionIndex].endTrigger === "string") {
                    if (typeof localAnswers === "string") {
                        return localAnswers === formData.questions[questionIndex].endTrigger;
                    } else {
                        return localAnswers.some((x: any) => x === formData.questions[questionIndex].endTrigger);
                    }
                } else {
                    if (typeof localAnswers === "string") {
                        return formData.questions[questionIndex].endTrigger.some((x: string) => x === localAnswers);
                    } else {
                        return formData.questions[questionIndex].endTrigger.some((x: string) => localAnswers.some((y: any) => x === y));
                    }
                }
            }
        }

        return false;
    };

    const handleNext = (localResults: any[]) => {
        try {
            let localStep = step;
            let skipTarget: string | undefined = undefined;
            let end = false;

            // Check if subquestions are skipped
            if (
                localResults.some(
                    (r: any) =>
                        formData.questions[step] &&
                        Object.keys(r).length &&
                        Object.keys(r)[0] &&
                        Object.keys(r)[0] === `question${formData.questions[step].questionNumber}` &&
                        (Object.values(r)[0] === "NEVER" || Object.values(r)[0] === "NONE" || Object.values(r)[0] === "NO")
                )
            ) {
                let foundNextQuestion = false;

                for (let i = step + 1; i < formData.questions.length && !foundNextQuestion; i++) {
                    if (
                        formData.questions[i] &&
                        formData.questions[i].questionNumber &&
                        alphabet.some(a => formData.questions[i].questionNumber.includes(a))
                    ) {
                        localStep++;
                    } else {
                        foundNextQuestion = true;
                    }
                }
            }
            // Subquestion trigger
            else if (
                localResults.some((r: any) => {
                    const questionNumber = Object.keys(localResults[localResults.length - 1])[0].replace("question", "");
                    const questionIndex = formData.questions.findIndex((q: any) => q.questionNumber === questionNumber);

                    // End trigger
                    if (
                        formData.questions[step] &&
                        Object.keys(r).length &&
                        Object.keys(r)[0] &&
                        formData.questions[questionIndex] &&
                        isEndTrigger(localResults, questionIndex)
                    ) {
                        end = true;
                        return true;
                    }

                    // Skip trigger
                    if (
                        !end &&
                        formData.questions[step] &&
                        Object.keys(r).length &&
                        Object.keys(r)[0] &&
                        formData.questions[questionIndex] &&
                        formData.questions[questionIndex].skipTrigger &&
                        isTrigger(localResults, questionIndex)
                    ) {
                        if (formData.questions[questionIndex].skipTo) skipTarget = formData.questions[questionIndex].skipTo;
                        return true;
                    }
                })
            ) {
                if (end) {
                    localStep = formData.questions.length - 1;
                }

                if (skipTarget) {
                    const skipTargetIndex = formData.questions.findIndex((q: any) => q.questionNumber === skipTarget);

                    if (skipTargetIndex !== -1) {
                        for (let j = localStep + 1; j !== skipTargetIndex; j++) {
                            localStep++;
                        }
                    }
                }
            }

            setData("");
            localStep++;
            setStep(localStep);
        } catch (e) {
            console.error(e);
        }
    };

    const handleBack = () => {
        let localStep = step;
        let index = step - 1;

        if (index !== 0) {
            if (!results.some(r => Object.keys(r)[0] === `question${formData.questions[index].questionNumber}`)) {
                localStep--;
            }
            index--;
        }

        localStep--;
        setStep(localStep);
    };

    const getImage = (formName: string) => {
        switch (formName) {
            default:
                return "";
            // Diet
            case "Eating Pattern Self Assessment":
                return dietEatingPattern;
            case "Diet History":
                return dietHistory;
            case "Mediterranean Diet Adherence":
                return "";
            // Cognitive
            case "Pre-Cognitive Expectancy Questionnaire":
                return "";
        }
    };

    const getText = (formName: string) => {
        switch (formName) {
            default:
                return "";
            case "Eating Pattern Self Assessment":
                return `The word “serving” refers to a portion of food that is meaningful in terms of its nutritional value.  Sometimes you may eat less or more than an official serving size.  You can add servings together, and a single portion can count as more than one serving.  For example:
                ½ cup of berries + medium banana on your breakfast cereal = 2 fruit servings
                1 large apple or banana = 2 fruit servings
                1 cup of romaine lettuce + ½ cup red pepper + ½ cup of celery in a salad = 3 vegetable servings
                1 medium sweet potato = 2 vegetable servings
                1 large pork chop or chicken breast = 2 meat servings
                `;
            case "Diet History":
                return "";
            case "Mediterranean Diet Adherence":
                return "";
            // Cognitive
            case "Pre-Cognitive Expectancy Questionnaire":
                return (
                    <div>
                        <div>
                            This questionnaire will ask you about your feelings regarding the effectiveness of lifestyle interventions and
                            recommendations, such as the one you will be involved in, to improve your thinking abilities. Please complete the items
                            listed below by circling the number next to each question that best indicates how you feel.
                        </div>
                        <div style={{ textDecoration: "underline" }}>Answer key:</div>
                        <ol>
                            <li>
                                <p style={{ fontWeight: "bold" }}>Completely unsuccessful</p>: no change in my thinking abilities. Such a procedure
                                would be a waste of time and resources.
                            </li>
                            <li>
                                <p style={{ fontWeight: "bold" }}>Fairly unsuccessful</p>: possible changes in my thinking abilities, yet unnoticeable
                                in daily life. Such a procedure would probably be a waste of time and resources.
                            </li>
                            <li>
                                <p style={{ fontWeight: "bold" }}>Somewhat unsuccessful</p>: possible changes in my thinking abilities, yet barely
                                noticeable in daily life. Such a procedure may be a waste of time and resources.
                            </li>
                            <li>
                                <p style={{ fontWeight: "bold" }}>I don’t know</p>: I am not sure what to think about it.
                            </li>
                            <li>
                                <p style={{ fontWeight: "bold" }}>Somewhat successful</p>: possible changes in my thinking abilities, somewhat
                                noticeable in daily life. Such a procedure may be a good investment of time and resources.
                            </li>
                            <li>
                                <p style={{ fontWeight: "bold" }}>Fairly successful</p>: probable changes in my thinking abilities, noticeable in
                                daily life. Such a procedure would probably be a good investment of time and resources.
                            </li>
                            <li>
                                <p style={{ fontWeight: "bold" }}>Completely successful</p>: changes in my thinking abilities that positively impact
                                daily life. Such a procedure would be a great investment of time and resources.
                            </li>
                        </ol>
                    </div>
                );
        }
    };

    const handleSubmit = (receivedResults: any, receivedAnswers: number[] | undefined) => {
        setLoading(true);
        onComplete(receivedResults, receivedAnswers ?? answers);
        setLoading(false);

        handleReset();

        enqueueSnackbar("questionnaire completed", { variant: "success" });

        if (window.location.href.includes("dashboard") && participantId) {
            hist.push(`/dashboard/participant/${participantId}/forms/0`);
        } else hist.push("/");
    };

    // Fetch previous answer
    useEffect(() => {
        if (step >= formData.questions.length) {
            handleSubmit(results, answers);
        }
        if (results.length > 0 && formData.questions[step] && formData.questions[step].questionNumber) {
            results.some(r => Object.keys(r)[0] === `question${formData.questions[step].questionNumber}`);
            const localData: any = results.find(r => Object.keys(r)[0] === `question${formData.questions[step].questionNumber}`);

            if (localData) setData(localData[`question${formData.questions[step].questionNumber}`]);
        }
    }, [step]);

    if (formData && formData.questions && formData.questions[step]) {
        return (
            <div className="question__container">
                {formData.title === "Exercise & Falls calendar" && formData.questions[step].questionNumber === "4" && (
                    <div className="centered__parent">
                        <img
                            src={borgScale}
                            alt=""
                            style={{ maxHeight: isLargeImage ? "none" : 400, cursor: "pointer" }}
                            onClick={() => setIsLargeImage(prev => !prev)}
                        />
                    </div>
                )}

                {formData.questions[step].instructions && (
                    <h4 style={{ fontWeight: "lighter", marginBottom: 20 }}>{formData.questions[step].instructions}</h4>
                )}
                <h3 className="question__title">Question {formData.questions[step] ? formData.questions[step].questionNumber : step + 1}</h3>
                <FormControl className="forms__removeMargin" component="fieldset">
                    <FormLabel component="legend" className="question__label">
                        <p>
                            {formData.questions[step] && formData.questions[step].question}
                            {/* <IconButton
                                color="inherit"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setSelectedTypes(["radio"]);
                                    setDialogOpen(true);
                                }}
                                className="question__label__icon"
                            >
                                <GrCircleInformation />
                            </IconButton> */}
                        </p>
                    </FormLabel>

                    <AnswerComponent
                        formData={formData}
                        type={formData.questions[step] ? formData.questions[step].type : "radio"}
                        data={data}
                        setData={setData}
                        setAnswer={setAnswer}
                        answers={answers}
                        step={step}
                    />
                </FormControl>
                <Grid item container spacing={2} className="question__buttons__container">
                    <Grid item xs={6} className="question__buttons">
                        <Button
                            className="question__buttons--back"
                            variant="contained"
                            color="inherit"
                            disabled={step === 0}
                            onClick={() => handleBack()}
                            sx={{ mr: 1 }}
                        >
                            <h6 className="question__buttons--text">
                                <FaLessThan /> Back
                            </h6>
                        </Button>
                    </Grid>
                    <Grid item xs={6} className="question__buttons">
                        <Button
                            className="question__buttons--next"
                            variant="contained"
                            onClick={() => handleData()}
                            disabled={
                                (!data && formData.questions[step] && formData.questions[step].type !== "textfield") ||
                                (formData.questions[step].type === "slider" && data === "")
                            }
                        >
                            <>
                                {step >= formData.questions.length - 1 ? (
                                    <h6 className="question__buttons--text">Finish</h6>
                                ) : (
                                    <h6 className="question__buttons--text">
                                        Next <FaGreaterThan />
                                    </h6>
                                )}
                            </>
                        </Button>
                    </Grid>
                    <Grid item xs={12} className="question__buttons">
                        <Tooltip title="This button will reset the form">
                            <Button className="cta__submit" fullWidth variant="contained" onClick={handleResetDialogOpen}>
                                <h6>Reset</h6>
                            </Button>
                        </Tooltip>
                        <Dialog
                            open={resetDialogOpen}
                            onClose={handleResetDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Reset Form"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to reset the form? This action cannot be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleResetDialogClose} color="success">
                                    No
                                </Button>
                                <Button onClick={handleConfirmReset} color="error" autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    <Grid item xs={12} className="question__buttons">
                        <Button className="cta__submit" fullWidth variant="contained" onClick={() => hist.goBack()}>
                            <h6>Close</h6>
                        </Button>
                    </Grid>
                </Grid>
                <InstructionDialog
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                    types={selectedTypes}
                    text={getText(formData.title)}
                    img={getImage(formData.title)}
                />
            </div>
        );
    } else return <></>;
};

interface FormGeneratorProps {
    formData: any;
    // eslint-disable-next-line
    onComplete: (data: any, answers: number[]) => void;
    // eslint-disable-next-line
    setOpen: (value: boolean) => void;
    participantId?: string;
}

const FormGenerator: React.FC<FormGeneratorProps> = ({ formData, onComplete, setOpen, participantId }) => {
    const [results, setResults] = useState<any[]>([]);
    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState<number[]>([]);

    useEffect(() => {
        return () => {
            setResults([]);
            setAnswers([]);
            setStep(0);
        };
    }, []);

    return (
        <div className="fg">
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "95%", mr: 1 }}>
                    <LinearProgress variant="determinate" value={(step / formData.questions.length) * 100} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${step + 1} / ${formData.questions.length}`}</Typography>
                </Box>
            </Box>

            <h2>{formData.title}</h2>

            {formData.instructions && <h6 style={{ paddingBottom: 20, paddingTop: 20 }}>Instructions: {formData.instructions}</h6>}
            {formData.about && <div style={{ paddingBottom: 20 }}>About: {formData.about}</div>}

            <div style={{ paddingTop: 40 }}>
                <Question
                    formData={formData}
                    onComplete={(data, answers) => {
                        onComplete(data, answers);
                    }}
                    setOpen={setOpen}
                    participantId={participantId}
                    results={results}
                    setResults={setResults}
                    step={step}
                    setStep={setStep}
                    answers={answers}
                    setAnswers={setAnswers}
                />
            </div>
        </div>
    );
};
export default FormGenerator;
