import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";

// Mui
import Button from "@mui/material/Button";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";

// Custom components
import Editor from "../../../general/ultraEditor";

// Utils
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import { doc, getDoc } from "firebase/firestore";
import { genDoc } from "../../../../utils/firebase";

interface Props {
    participantId: string;
    // eslint-disable-next-line
    onComplete: (object: any) => void;
}

const LogbookForMedicalNotes: React.FC<Props> = ({ participantId, onComplete }) => {
    const editorRef = useRef<any>(null);
    const { setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);

    const fetchParticipant = async () => {
        if (participantId) {
            const payload = await getDoc(doc(firestore, "Participants", participantId));
            setParticipant(genDoc<Participant>()(payload));
        }
    };

    const handleSaveNotes = async () => {
        try {
            setLoading(true);
            if (editorRef.current === null) {
                console.error("editor ref has not been set");
                return;
            }
            if (participant && participant.id) {
                onComplete({ notes: await editorRef.current.getRawContent() });
            }
        } catch (e: any) {
            if (e.response && e.response.data) enqueueSnackbar(e.response.data, { variant: "error" });
            else console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParticipant();
    }, [participantId]);

    return (
        <div className="logbookForMedicalNotes__container">
            <div className="centered__parent">
                <h1 style={{ marginBottom: 20 }}>Logbook for Medical notes</h1>
            </div>
            <Editor ref={editorRef} rawContent={participant?.forms.logbookForMedicalNotes?.notes} />

            <div style={{ display: "flex" }}>
                <Button
                    variant="contained"
                    color="primary"
                    className="cta__submit"
                    style={{ marginLeft: "auto", marginTop: 20 }}
                    onClick={() => handleSaveNotes()}
                >
                    Save Logbook for Medical notes
                </Button>
            </div>
        </div>
    );
};

export default LogbookForMedicalNotes;
