import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Moment } from "moment";

// Mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// Assets
import physicalExerciseLogo from "../../../assets/images/logos/physicalExerciseLogo.png";

// Icons
import { IoChevronBack } from "react-icons/io5";
import { RiFileList2Line } from "react-icons/ri";

// Custom component
import TopMenu from "../../general/TopMenu";
import MediaCard from "../../general/MediaCard";
import FileCard from "../../general/FileCard";
import SurveyDialog from "./questionnaires/SurveyDialog";
import FallsCalendar from "../../general/FallsCalendar";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Interfaces
import { File } from "../../../interfaces/File";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../utils/firebase";

// Utils
import { getIcon } from "../../../utils/general";
import MyGoals from "../../general/MyGoals";

const PhysicalExercise: React.FC = () => {
    const hist = useHistory();
    const { currentParticipant, setLoading } = useFetchUser();
    const { date } = useParams<any>();

    // States
    const [content, setContent] = useState<File[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [open, setOpen] = useState(false);

    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [events, setEvents] = useState<any[]>([]);

    const fetchParticipantContent = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.physical.content.length) {
            const localContent: File[] = [];

            for (const c of currentParticipant.physical.content) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localContent.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) {
                        const privateContent = genDoc<File>()(payload);
                        privateContent.participantId = currentParticipant.id;
                        localContent.push(privateContent);
                    }
                }
            }
            setContent(localContent);
        }
    };

    const fetchParticipantFiles = async () => {
        try {
            if (currentParticipant && currentParticipant.id && currentParticipant.physical.files.length) {
                const localFiles: File[] = [];
                for (const c of currentParticipant.physical.files) {
                    let payload = await getDoc(doc(firestore, "Files", c));

                    if (payload && payload.data()) localFiles.push(genDoc<File>()(payload));
                    else {
                        payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                        if (payload) localFiles.push(genDoc<File>()(payload));
                    }
                }
                setFiles(localFiles);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const loadData = async () => {
        if (currentParticipant) {
            await fetchParticipantContent();
            await fetchParticipantFiles();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentParticipant) {
            loadData();
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (!open) setSelectedDate(null);
    }, [open]);

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: "Visited physical page" }],
            });
        }
    }, []);

    return (
        <>
            <TopMenu title="Your personalized workout" icon={physicalExerciseLogo} backButton />

            <div className="pe__container">
                <FallsCalendar date={date} events={events} setEvents={setEvents} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                <div>
                    <h4 className="home__title" style={{ marginBottom: 20 }}>
                        Interactive content
                    </h4>
                    <Grid container item spacing={1} style={{ marginBottom: 20, alignItems: "stretch" }}>
                        {content.map((c, i) => (
                            <Grid key={i} item xs={4}>
                                <MediaCard
                                    title={c.title}
                                    onClick={async () => {
                                        if (currentParticipant && currentParticipant.id) {
                                            // Private content
                                            if (c.participantId) {
                                                hist.push(`videoViewer/${c.id}/${currentParticipant.id}`);
                                            }
                                            // Shared content
                                            else {
                                                hist.push(`videoViewer/${c.id}/undefined`);
                                            }
                                            // Participant log
                                            await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                logs: [
                                                    ...(currentParticipant?.logs ?? []),
                                                    { date: new Date(), action: `Viewed physical video ${c.title} (${c.id})` },
                                                ],
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div>
                    <h4 className="home__title" style={{ marginBottom: 20 }}>
                        Personalized Instructions
                    </h4>
                    <Grid container item spacing={1}>
                        {files.map((f, i) => (
                            <FileCard
                                key={i}
                                title={f.title}
                                icon={getIcon(f.extension)}
                                onClick={async () => {
                                    if (currentParticipant && currentParticipant.id) {
                                        // Participant log
                                        await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                            logs: [
                                                ...(currentParticipant?.logs ?? []),
                                                { date: new Date(), action: `Viewed physical file ${f.title} (${f.id})` },
                                            ],
                                        });
                                    }
                                    window.open(f.url, "_blank");
                                }}
                            />
                        ))}
                    </Grid>
                </div>

                <MyGoals domain="physical" />

                <div>
                    <Grid item md={12} className="home__optionsContainer">
                        <Grid item xs={12} onClick={() => hist.push("/")} className="home__options__card">
                            <h4 className="home__options__title">Back to Home</h4>
                            <IoChevronBack className="home__options__image" />
                            <IoChevronBack className="home__options__background" />
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid item md={12} className="home__optionsContainer">
                        <Grid item xs={12} onClick={() => setOpen(true)} className="home__options__card">
                            <Button className="home__options__title">
                                <h4>Complete survey</h4>
                            </Button>
                            <RiFileList2Line className="home__options__image" />
                            <RiFileList2Line className="home__options__background" />
                        </Grid>
                    </Grid>
                </div>
            </div>
            {open && <SurveyDialog open={open} setOpen={setOpen} topic="Physical" />}
        </>
    );
};

export default PhysicalExercise;
