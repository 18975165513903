import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Custom component
import FormGenerator from "./questionnaires/FormGenerator";
import HealthResourceUtilization from "./questionnaires/HealthResourceUtilization";

// Mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { updateDoc, doc, addDoc, collection, getDoc, serverTimestamp } from "firebase/firestore";
import { genDoc } from "../../../utils/firebase";

// Data
import { qualityOfLife, healthUtilityIndex, healthBehaviorsQuestionnaire } from "./questionnaires/data/VascularHealth";
import { lawtonBrodyIADL, fallsAndBalance, womenHealth } from "./questionnaires/data/PhysicalExerciseActivityData";
import { dietHistory, eatingPattern, mediterraneanDietAdherence } from "./questionnaires/data/DietData";
import { insomniaSeverityIndex } from "./questionnaires/data/SleepData";
import {
    preCognitiveExpectancy,
    postCognitiveExpectancy,
    generalizedAnxietyDisorder,
    geriatricDepressionScale,
    revisedHearingHandicapInventory,
    visionHearing,
    bhproQuestionnaires,
} from "./questionnaires/data/CognitiveData";

// Interfaces
import { Stat } from "../../../interfaces/Stat";
import { Participant } from "../../../interfaces/Participant";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Utils
import { formatData, getScore } from "../../../utils/survey";
import { getTopic } from "../../../utils/topic";
import moment, { Moment } from "moment";
import HealthStatus from "./questionnaires/HealthStatus";

interface Params {
    participantId: string;
    questionnaireName: string;
}

const Survey: React.FC = () => {
    const hist = useHistory();
    const { participantId, questionnaireName } = useParams<Params>();
    const { currentParticipant } = useFetchUser();

    // States
    const [date, setDate] = useState<Moment | null>(moment(new Date()));

    const handleComplete = async (data: any, answers: number[]) => {
        if (participantId) {
            const localStat: Stat = {
                createdAt: date ? date.toDate() : (serverTimestamp() as any),
                updatedAt: date ? date.toDate() : (serverTimestamp() as any),
                isDeleted: false,
                participantId: participantId,
                topic: getTopic(questionnaireName),
                results: formatData(data),
                score: getScore(data, answers, questionnaireName),
                answers,
                questionnaireName,
            };

            if (date) {
                (localStat as any).createdDate = serverTimestamp();
            }

            const createPayload = await addDoc(collection(firestore, "Stats"), localStat);
            await updateDoc(doc(firestore, "Stats", createPayload.id), { id: createPayload.id });

            // Participant log
            const partPayload = await getDoc(doc(firestore, "Participants", participantId));
            const part = genDoc<Participant>()(partPayload);
            await updateDoc(doc(firestore, "Participants", participantId), {
                logs: [...part.logs, { date: new Date(), action: `Completed ${questionnaireName} questionnaire` }],
            });
        } else if (currentParticipant && currentParticipant.id) {
            const localStat: Stat = {
                createdAt: serverTimestamp() as any,
                updatedAt: serverTimestamp() as any,
                isDeleted: false,
                participantId: currentParticipant.id,
                topic: getTopic(questionnaireName),
                results: formatData(data),
                score: getScore(data, answers, questionnaireName),
                answers,
                questionnaireName,
            };
            const createPayload = await addDoc(collection(firestore, "Stats"), localStat);
            await updateDoc(doc(firestore, "Stats", createPayload.id), { id: createPayload.id });

            // Participant log
            await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: `Completed ${questionnaireName} questionnaire` }],
            });
        }
        if (questionnaireName === "healthResourceUtilization") hist.push("/");
    };

    const getFormData = () => {
        // Physical
        if (questionnaireName === "lawtonBrodyIADL") return lawtonBrodyIADL;
        // if (questionnaireName === "fallsCalendar") return fallsCalendar;
        if (questionnaireName === "fallsAndBalance") return fallsAndBalance;
        if (questionnaireName === "womenHealth") return womenHealth;
        // Health
        if (questionnaireName === "qualityOfLife") return qualityOfLife;
        if (questionnaireName === "healthUtilityIndex") return healthUtilityIndex;
        if (questionnaireName === "healthBehaviorsQuestionnaire") return healthBehaviorsQuestionnaire;
        // Diet
        if (questionnaireName === "dietHistory") return dietHistory;
        if (questionnaireName === "eatingPattern") return eatingPattern;
        if (questionnaireName === "mediterraneanDietAdherence") return mediterraneanDietAdherence;
        // Sleep
        if (questionnaireName === "insomniaSeverityIndex") return insomniaSeverityIndex;
        // Cognitive
        if (questionnaireName === "preCognitiveExpectancy") return preCognitiveExpectancy;
        if (questionnaireName === "postCognitiveExpectancy") return postCognitiveExpectancy;
        if (questionnaireName === "generalizedAnxietyDisorder") return generalizedAnxietyDisorder;
        if (questionnaireName === "geriatricDepressionScale") return geriatricDepressionScale;
        if (questionnaireName === "revisedHearingHandicapInventory") return revisedHearingHandicapInventory;
        if (questionnaireName === "visionHearing") return visionHearing;
        if (questionnaireName === "bhproQuestionnaires") return bhproQuestionnaires;
    };

    const RenderSurvey: React.FC = () => {
        if (questionnaireName === "healthResourceUtilization") {
            return <HealthResourceUtilization onComplete={handleComplete} />;
        } else if (questionnaireName === "healthStatus") {
            return <HealthStatus onComplete={handleComplete} />;
        } else {
            return <FormGenerator formData={getFormData()} onComplete={handleComplete} setOpen={() => undefined} participantId={participantId} />;
        }
    };

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: "Visited survey page" }],
            });
        }
    }, []);

    return (
        <>
            {participantId && questionnaireName && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        openTo="year"
                        disableFuture
                        views={["day"]}
                        label="Custom date"
                        inputFormat="DD/MM/YYYY"
                        value={date}
                        onChange={value => setDate(value)}
                        renderInput={(params: any) => <TextField fullWidth variant="outlined" required style={{ marginBottom: 10 }} {...params} />}
                    />
                </LocalizationProvider>
            )}

            <div className="survey__container">
                <RenderSurvey />
            </div>
        </>
    );
};

export default Survey;
