import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

// Mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { genDoc } from "../../../utils/firebase";

// Assets
import cognitiveTrainingLogo from "../../../assets/images/logos/cognitiveTrainingLogo.png";
import neuropeakLogo from "../../../assets/images/logos/neuropeakLogo.png";
import copyIcon from "../../../assets/icons/copyIcon.png";

// Icons
import { IoChevronBack } from "react-icons/io5";
import { RiFileList2Line } from "react-icons/ri";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Interfaces
import { File } from "../../../interfaces/File";

// Custom component
import TopMenu from "../../general/TopMenu";
import FileCard from "../../general/FileCard";
import SurveyDialog from "./questionnaires/SurveyDialog";

// Utils
import { getIcon } from "../../../utils/general";
import MyGoals from "../../general/MyGoals";
import MediaCard from "../../general/MediaCard";

const CognitiveTraining: React.FC = () => {
    const { currentParticipant, setLoading } = useFetchUser();
    const hist = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [content, setContent] = useState<File[]>([]);

    const fetchFiles = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.cognitive.files.length) {
            const localFiles: File[] = [];
            for (const c of currentParticipant.cognitive.files) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localFiles.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) localFiles.push(genDoc<File>()(payload));
                }
            }
            setFiles(localFiles);
        }
    };

    const fetchContent = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.cognitive.content.length) {
            const localContent: File[] = [];
            for (const c of currentParticipant.cognitive.content) {
                let payload = await getDoc(doc(firestore, "Files", c));
                if (payload && payload.data()) localContent.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) {
                        const privateContent = genDoc<File>()(payload);
                        privateContent.participantId = currentParticipant.id;
                        localContent.push(privateContent);
                    }
                }
            }
            setContent(localContent);
        }
    };

    const loadData = async () => {
        if (currentParticipant) {
            await fetchFiles();
            await fetchContent();
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentParticipant) {
            loadData();
        }
    }, [currentParticipant]);

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: "Visited cognitive page" }],
            });
        }
    }, []);

    return (
        <>
            <TopMenu title="Your cognitive training" icon={cognitiveTrainingLogo} backButton />

            <div className="pe__container">
                <div className="portalLayout__title">
                    <div className="ct__app">
                        <div className="centered__parent neuropeak__logoContainer">
                            <img src={neuropeakLogo} alt="logo" className="neuropeak__logo nofilterimg" />
                        </div>
                        <div className="ct__neuropeak__content">
                            <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                                Your cognitive training involves two 30-minute sessions per week performing exercises designed to challenge one or
                                more of your brain’s abilities, such as your ability to concentrate or to do two things at once. The exercises in this
                                training program involve a gradual increase in difficulty level designed to stimulate the ongoing improvement of your
                                cognitive abilities
                            </p>
                            {currentParticipant && (
                                <>
                                    <div className="ct__neuropeak__projectContainer">
                                        <h5>Project id : </h5>
                                        <TextField
                                            className="ct__neuropeak__input"
                                            value={currentParticipant.cognitive.neuropeakCredentials.projectId}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={async () => {
                                                                await navigator.clipboard.writeText(
                                                                    currentParticipant.cognitive.neuropeakCredentials.projectId
                                                                );
                                                                enqueueSnackbar("Project id copied to clipboard", { variant: "success" });
                                                            }}
                                                        >
                                                            <img src={copyIcon} alt="icon" className="nofilterimg" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div className="ct__neuropeak__projectContainer">
                                        <h5>Participant id : </h5>
                                        <TextField
                                            className="ct__neuropeak__input"
                                            id="participantId"
                                            value={currentParticipant.cognitive.neuropeakCredentials.participantId}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={async () => {
                                                                await navigator.clipboard.writeText(
                                                                    currentParticipant.cognitive.neuropeakCredentials.participantId
                                                                );
                                                                enqueueSnackbar("Participant id copied to clipboard", { variant: "success" });
                                                            }}
                                                        >
                                                            <img src={copyIcon} alt="icon" className="nofilterimg" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            <div className="ct__neuropeak__projectContainer">
                                <Button
                                    className="cta__blue"
                                    fullWidth
                                    variant="contained"
                                    onClick={async () => {
                                        window.open("https://neuropeak.leadhouse.app/", "_blank");

                                        if (currentParticipant && currentParticipant.id) {
                                            // Participant log
                                            await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: "Clicked on neuropeak app button" }],
                                            });
                                        }
                                    }}
                                >
                                    Go to app <span className="ct__neuropeak__btnArrow">→</span>
                                </Button>
                            </div>
                        </div>
                        <MyGoals domain="cognitive" />
                    </div>
                </div>
                <div>
                    <h4 className="home__title">Memo</h4>
                    <div className="ct__memo">
                        <p style={{ textAlign: "justify", textJustify: "inter-word" }}>
                            The MEMO program consists of 20 to 30 minute one-on-one sessions with your coach, once every 2 weeks. The MEMO program
                            will allow you to learn more about how your memory works and discover strategies to help you retain information more
                            easily. You will be assigned homework to complete during the week you do not meet with your coach.
                        </p>
                        <div className="ct__timeline">
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 1 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    1
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 2 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    2
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 3 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    3
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 4 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    4
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 5 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    5
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 6 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    6
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 7 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    7
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 8 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    8
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 9 ? { backgroundColor: "#4ECBD3" } : {}
                                    }
                                >
                                    9
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 10
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    10
                                </div>
                            </div>
                        </div>
                        <div className="ct__timeline">
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 11
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    11
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 12
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    12
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 13
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    13
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 14
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    14
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 15
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    15
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 16
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    16
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 17
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    17
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 18
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    18
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 19
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    19
                                </div>
                            </div>
                            <div className="ct__timeline__separator"></div>
                            <div className="ct__timeline__item">
                                <div
                                    className="ct__timeline__circle"
                                    style={
                                        currentParticipant && currentParticipant.cognitive.appointmentIndex >= 20
                                            ? { backgroundColor: "#4ECBD3" }
                                            : {}
                                    }
                                >
                                    20
                                </div>
                            </div>
                        </div>
                        {/* <div className="ct__memo__meeting">
                            <div className="ct__memo__textContainer">
                                <h5 className="ct__memo__meeting__next">Next meeting :</h5>
                                <h4 className="ct__memo__meeting__date">Tuesday Feb 17th at 17:00</h4>
                            </div>
                            <div className="ct__neuropeak__projectContainer">
                                <Button
                                    className="cta__blue"
                                    fullWidth
                                    variant="contained"
                                    onClick={() => window.open("https://neuropeak.leadhouse.app/", "_blank")}
                                >
                                    Go to meeting <span className="ct__neuropeak__btnArrow">→</span>
                                </Button>
                            </div>
                        </div> */}
                    </div>
                    <Grid item xs={12}>
                        <h4 className="home__title">Interactive content</h4>
                        <Grid container item>
                            {content.map((c, i) => (
                                <Grid key={i} item xs={4}>
                                    <MediaCard
                                        title={c.title}
                                        onClick={async () => {
                                            if (currentParticipant && currentParticipant.id) {
                                                // Private content
                                                if (c.participantId) {
                                                    hist.push(`videoViewer/${c.id}/${currentParticipant.id}`);
                                                }
                                                // Shared content
                                                else {
                                                    hist.push(`videoViewer/${c.id}/undefined`);
                                                }

                                                // Participant log
                                                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                    logs: [
                                                        ...(currentParticipant?.logs ?? []),
                                                        { date: new Date(), action: `Viewed health video ${c.title} (${c.id})` },
                                                    ],
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <h4 className="home__title" style={{ marginTop: 20, marginBottom: 20 }}>
                            Personalized Instructions
                        </h4>
                        <Grid container item spacing={1}>
                            {files.map((f, i) => (
                                <FileCard
                                    key={i}
                                    title={f.title}
                                    icon={getIcon(f.extension)}
                                    onClick={async () => {
                                        if (currentParticipant && currentParticipant.id) {
                                            // Participant log
                                            await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                logs: [
                                                    ...(currentParticipant?.logs ?? []),
                                                    { date: new Date(), action: `Viewed cognitive file ${f.title} (${f.id})` },
                                                ],
                                            });
                                        }
                                        window.open(f.url, "_blank");
                                    }}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </div>

                {/* <Grid item xs={12} md={6}>
                    <div style={{ margin: "40px 5px 0px 0px" }}>
                        <Button fullWidth variant="contained" style={{ minHeight: 75 }} onClick={() => hist.push("/")}>
                            Back to Home
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ margin: "40px 0px 0px 5px" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            style={{ minHeight: 75 }}
                            onClick={() => setOpen(true)}
                            disabled={
                                currentParticipant
                                    ? !currentParticipant.questionnaires.some(
                                          q => q === "cognitiveExpectancy" || q === "generalizedAnxietyDisorder" || q === "geriatricDepressionScale"
                                      )
                                    : false
                            }
                           >
                            Complete survey
                        </Button>
                    </div>
                </Grid> */}
                {/* </div> */}

                <div>
                    <Grid item md={12} className="home__optionsContainer">
                        <Grid item xs={12} onClick={() => hist.push("/")} className="home__options__card">
                            <h4 className="home__options__title">Back to Home</h4>
                            <IoChevronBack className="home__options__image" />
                            <IoChevronBack className="home__options__background" />
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <Grid item md={12} className="home__optionsContainer">
                        <Grid item xs={12} onClick={() => setOpen(true)} className="home__options__card">
                            <Button className="home__options__title">
                                <h4>Complete survey</h4>
                            </Button>
                            <RiFileList2Line className="home__options__image" />
                            <RiFileList2Line className="home__options__background" />
                        </Grid>
                    </Grid>
                </div>
            </div>
            {open && <SurveyDialog open={open} setOpen={setOpen} topic="Cognitive" />}
        </>
    );
};

export default CognitiveTraining;
