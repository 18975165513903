import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

// Mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// Assets
import sleepLogo from "../../../assets/images/logos/sleepLogo.png";

// Icons
import { IoChevronBack } from "react-icons/io5";
import { RiFileList2Line } from "react-icons/ri";

// Interfaces
import { File } from "../../../interfaces/File";

// Custom component
import TopMenu from "../../general/TopMenu";
import SurveyDialog from "./questionnaires/SurveyDialog";
import FileCard from "../../general/FileCard";
import MediaCard from "../../general/MediaCard";

// Utils
import { getIcon } from "../../../utils/general";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Firebase
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { genDoc } from "../../../utils/firebase";
import MyGoals from "../../general/MyGoals";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Participant } from "../../../interfaces/Participant";
import SleepCalendar from "../../general/SleepCalendar";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
}));

const MAXIMUM_SCORE = 5;

interface DisplayDayProps {
    events: any[];
    dayNumber: number;
}

const DisplayDay: React.FC<DisplayDayProps> = ({ events, dayNumber }) => {
    const getPreviousWeekDay = (weekDay = 0, date: Date | null = null) => {
        const prevWeekDay = (date && new Date(date.valueOf())) || new Date();
        prevWeekDay.setDate(prevWeekDay.getDate() - ((prevWeekDay.getDay() + (7 - weekDay)) % 7));

        // if current day is requested day, take last week's day
        if (moment(prevWeekDay).format("MM/DD/YYYY") === moment(new Date()).format("MM/DD/YYYY")) {
            prevWeekDay.setDate(prevWeekDay.getDate() - 7);
            return prevWeekDay;
        }

        return prevWeekDay;
    };

    const getDayData = (weekDay: number) => {
        let data: any = undefined;

        if (weekDay >= 0 && weekDay <= 7) {
            const date = getPreviousWeekDay(weekDay);

            if (date && events && events.length) {
                const dataFound = events.find((e: any) => moment(e.date.seconds * 1000).format("DD/MM/YYYY") === moment(date).format("DD/MM/YYYY"));

                if (dataFound) data = dataFound;
            }
        }

        return data;
    };

    const getWeekName = () => {
        if (dayNumber === 0) return "SUN";
        if (dayNumber === 1) return "MON";
        if (dayNumber === 2) return "TUE";
        if (dayNumber === 3) return "WED";
        if (dayNumber === 4) return "THU";
        if (dayNumber === 5) return "FRI";
        if (dayNumber === 6) return "SAT";
        else return "";
    };

    const getQuestion10StringValue = (value: number) => {
        switch (value) {
            default:
            case 1:
                return "Very relaxing";
            case 2:
                return "Restful";
            case 3:
                return "Neutral";
            case 4:
                return "Not very relaxing";
            case 5:
                return "Not restful at all";
        }
    };

    const getRealisticScore = (score: number) => {
        return MAXIMUM_SCORE - score;
    };

    return (
        <>
            <Grid item xs={2} className="sleep__graph__days">
                <h6>{getWeekName()}</h6>
            </Grid>
            <Grid className="sleep__graph__data" item xs={10}>
                {getDayData(dayNumber) ? (
                    <Tooltip title={`${getQuestion10StringValue(getDayData(dayNumber).value.question10)}`} placement="top">
                        <BorderLinearProgress
                            variant="determinate"
                            value={(getRealisticScore(getDayData(dayNumber).value.question10) / MAXIMUM_SCORE) * 100}
                            style={{ marginTop: 3 }}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title="Not completed yet" placement="top">
                        <BorderLinearProgress variant="determinate" value={0} style={{ marginTop: 3, backgroundColor: "#363636" }} />
                    </Tooltip>
                )}
            </Grid>
        </>
    );
};

interface Last7DaysProps {
    events: any[];
}

const Last7Days: React.FC<Last7DaysProps> = ({ events }) => {
    const dayNumber = new Date().getDay();

    const dayOrder = [];
    let index = dayNumber;

    while (dayOrder.length !== 7) {
        if (index === 6) {
            dayOrder.push(0);
            index = 0;
        } else {
            dayOrder.push(index + 1);
            index++;
        }
    }

    return (
        <>
            <DisplayDay events={events} dayNumber={dayOrder[0]} />
            <DisplayDay events={events} dayNumber={dayOrder[1]} />
            <DisplayDay events={events} dayNumber={dayOrder[2]} />
            <DisplayDay events={events} dayNumber={dayOrder[3]} />
            <DisplayDay events={events} dayNumber={dayOrder[4]} />
            <DisplayDay events={events} dayNumber={dayOrder[5]} />
            <DisplayDay events={events} dayNumber={dayOrder[6]} />
        </>
    );
};

const Sleep: React.FC = () => {
    const hist = useHistory();

    const { currentParticipant } = useFetchUser();

    // States
    const [surveyDialogOpen, setSurveyDialogOpen] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [content, setContent] = useState<File[]>([]);
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);

    const fetchSleepFiles = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.sleep.files && currentParticipant.sleep.files.length) {
            const localFiles: File[] = [];
            for (const c of currentParticipant.sleep.files) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localFiles.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) localFiles.push(genDoc<File>()(payload));
                }
            }
            setFiles(localFiles);
        }
    };

    const fetchSleepContent = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.sleep.content && currentParticipant.sleep.content.length) {
            const localContent: File[] = [];
            for (const c of currentParticipant.sleep.content) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localContent.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) {
                        const privateContent = genDoc<File>()(payload);
                        privateContent.participantId = currentParticipant.id;
                        localContent.push(privateContent);
                    }
                }
            }
            setContent(localContent);
        }
    };

    const fetchParticipant = () => {
        if (currentParticipant && currentParticipant.id) {
            onSnapshot(doc(firestore, "Participants", currentParticipant.id), payload => {
                setParticipant(genDoc<Participant>()(payload));
            });
        }
    };

    useEffect(() => {
        if (currentParticipant) {
            fetchParticipant();
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (participant) {
            fetchSleepFiles();
            fetchSleepContent();
        }
    }, [participant]);

    if (participant)
        return (
            <>
                <TopMenu title="Your sleep diary" icon={sleepLogo} backButton />

                <div className="sleep">
                    <div className="portalLayout__title">
                        <div className="centered__parent">
                            <SleepCalendar participant={participant} />
                        </div>
                    </div>
                    <div className="sleep__graph">
                        <Grid container item>
                            <Grid item xs={2} className="sleep__graph__days"></Grid>
                            <Grid item xs={10} className="sleep__graph__data">
                                <div className="centered__parent">
                                    <h4 className="home__title sleep__graph__title"> Sleep over last 7 days (question 10)</h4>
                                </div>
                                <div className="centered__parent">
                                    <h5 className="home__title sleep__graph__title"> I find that my sleep has been:</h5>
                                </div>
                            </Grid>

                            <Last7Days events={participant.sleep.dates} />

                            <Grid item xs={2} className="sleep__graph__days">
                                <span className="sleep__blackout">.</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="portalLayout__title">
                        <h4 className="home__title">Interactive content</h4>
                        <Grid container item>
                            {!content.length ? (
                                <div style={{ marginLeft: 5 }}>No media.</div>
                            ) : (
                                content.map((c, i) => (
                                    <Grid key={i} item xs={4}>
                                        <MediaCard
                                            title={c.title}
                                            onClick={async () => {
                                                if (currentParticipant && currentParticipant.id) {
                                                    // Private content
                                                    if (c.participantId) {
                                                        hist.push(`videoViewer/${c.id}/${currentParticipant.id}`);
                                                    }
                                                    // Shared content
                                                    else {
                                                        hist.push(`videoViewer/${c.id}/undefined`);
                                                    }
                                                    // Participant log
                                                    await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                        logs: [
                                                            ...(currentParticipant?.logs ?? []),
                                                            { date: new Date(), action: `Viewed sleep video ${c.title} (${c.id})` },
                                                        ],
                                                    });
                                                }
                                            }}
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </div>
                    <div className="portalLayout__title">
                        <h4 className="home__title">Personalized Instructions</h4>
                        <div className="sleep__files">
                            {!files.length ? (
                                <p style={{ marginLeft: 5 }}>No file.</p>
                            ) : (
                                files.map((f, i) => (
                                    <FileCard
                                        key={i}
                                        title={f.title}
                                        icon={getIcon(f.extension)}
                                        onClick={async () => {
                                            if (currentParticipant && currentParticipant.id) {
                                                // Participant log
                                                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                    logs: [
                                                        ...(currentParticipant?.logs ?? []),
                                                        { date: new Date(), action: `Viewed sleep file ${f.title} (${f.id})` },
                                                    ],
                                                });
                                            }
                                            window.open(f.url, "_blank");
                                        }}
                                    />
                                ))
                            )}
                        </div>
                        <MyGoals domain="sleep" />
                    </div>
                    <div>
                        <Grid item md={6} className="home__optionsContainer">
                            <Grid item xs={12} onClick={() => hist.push("/")} className="home__options__card">
                                <h4 className="home__options__title">Back to Home</h4>
                                <IoChevronBack className="home__options__image" />
                                <IoChevronBack className="home__options__background" />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Grid item md={6} className="home__optionsContainer">
                            <Grid item xs={12} onClick={() => setSurveyDialogOpen(true)} className="home__options__card">
                                <Button className="home__options__title">
                                    <h4>Complete survey</h4>
                                </Button>
                                <RiFileList2Line className="home__options__image" />
                                <RiFileList2Line className="home__options__background" />
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {surveyDialogOpen && <SurveyDialog open={surveyDialogOpen} setOpen={setSurveyDialogOpen} topic="Sleep" />}
            </>
        );
    return <></>;
};

export default Sleep;
