import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { genDoc } from "../../../utils/firebase";

// Assets
import dietLogo from "../../../assets/images/logos/dietLogo.png";
import breakfastIcon from "../../../assets/icons/diet/breakfast.png";
import lunchIcon from "../../../assets/icons/diet/lunch.png";
import dinnerIcon from "../../../assets/icons/diet/dinner.png";
import snackIcon from "../../../assets/icons/diet/snack.png";

// Interfaces
import { File } from "../../../interfaces/File";

// Icons
import { IoChevronBack } from "react-icons/io5";
import { RiFileList2Line } from "react-icons/ri";

// Custom component
import TopMenu from "../../general/TopMenu";
import SurveyDialog from "./questionnaires/SurveyDialog";
import FileCard from "../../general/FileCard";
import MediaCard from "../../general/MediaCard";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Utils
import { getIcon } from "../../../utils/general";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Typography>{children}</Typography>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface MealCardProps {
    title: string;
    icon: string;
    items: string;
    type: string;
    day: string;
    name: string;
    onUpdate: any;
}

const MealCard: React.FC<MealCardProps> = ({ title, icon, items, type, day, name, onUpdate }) => {
    // States
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [text3, setText3] = useState("");
    const [text4, setText4] = useState("");

    useEffect(() => {
        const texts = items.split("\n");

        if (texts.length) {
            if (texts.length >= 1) setText1(texts[0]);
            if (texts.length >= 2) setText2(texts[1]);
            if (texts.length >= 3) setText3(texts[2]);
            if (texts.length >= 4) setText4(texts[3]);
        }
    }, []);

    return (
        <Grid container className="mealCard">
            <Grid item xs={3} className="centered__parent mealCard__iconContainer">
                <img src={icon} alt="icon" className="mealCard__icon nofilterimg" />
            </Grid>
            <Grid item xs={8} className="mealCard__content">
                <h4>
                    <span className="progress__better">/</span> <span>{title}</span>
                </h4>
                <TextField
                    fullWidth
                    multiline
                    color="primary"
                    variant="standard"
                    value={text1}
                    InputProps={{ disableUnderline: true }}
                    onChange={e => setText1(e.target.value)}
                    onBlur={() => onUpdate(`${text1}\n${text2}\n${text3}\n${text4}`, type, day, name)}
                />

                <TextField
                    fullWidth
                    variant="standard"
                    value={text2}
                    InputProps={{ disableUnderline: true }}
                    onChange={e => setText2(e.target.value)}
                    onBlur={() => onUpdate(`${text1}\n${text2}\n${text3}\n${text4}`, type, day, name)}
                />

                <TextField
                    fullWidth
                    variant="standard"
                    value={text3}
                    InputProps={{ disableUnderline: true }}
                    onChange={e => setText3(e.target.value)}
                    onBlur={() => onUpdate(`${text1}\n${text2}\n${text3}\n${text4}`, type, day, name)}
                />

                <TextField
                    fullWidth
                    variant="standard"
                    value={text4}
                    InputProps={{ disableUnderline: true }}
                    onChange={e => setText4(e.target.value)}
                    onBlur={() => onUpdate(`${text1}\n${text2}\n${text3}\n${text4}`, type, day, name)}
                />
            </Grid>
        </Grid>
    );
};

interface DietParams {
    section: string;
    day: string;
}

const DietSection: React.FC<DietParams> = ({ section, day }) => {
    const { currentParticipant } = useFetchUser();
    let currentDay: any = null;
    const sectionPart = section.includes("typical") ? "typical" : "recommendation";

    if (currentParticipant) {
        Object.entries(currentParticipant.diet).map(e => {
            if (e[0] === section && typeof e[1] !== "string") {
                Object.entries(e[1]).map(d => {
                    if (d[0] === day) {
                        currentDay = d[1];
                    }
                });
            } else if (e[0] === section && typeof e[1] === "string") {
                currentDay = e[1];
            }
        });

        const handleUpdate = async (data: string, type: string, day: string, name: string) => {
            if (currentParticipant && currentParticipant.id) {
                if (type === "typical") {
                    (currentParticipant as any).diet.typicalWeek[day][name] = data;

                    await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                        ...currentParticipant,
                    });

                    // Participant log
                    await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                        logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: `Updated diet` }],
                    });
                }
            }
        };

        if (typeof currentDay !== "string")
            return (
                <>
                    <Grid container className="diet__meals">
                        <Grid item xs={6} className="diet__mealsContainer">
                            <MealCard
                                title="Breakfast"
                                icon={breakfastIcon}
                                items={currentDay[`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Breakfast`]}
                                type={sectionPart}
                                day={day}
                                name={`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Breakfast`}
                                onUpdate={handleUpdate}
                            />
                        </Grid>
                        <Grid item xs={6} className="diet__mealsContainer">
                            <MealCard
                                title="Lunch"
                                icon={lunchIcon}
                                items={currentDay[`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Lunch`]}
                                type={sectionPart}
                                day={day}
                                name={`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Lunch`}
                                onUpdate={handleUpdate}
                            />
                        </Grid>
                        <Grid item xs={6} className="diet__mealsContainer">
                            <MealCard
                                title="Snack"
                                icon={snackIcon}
                                items={currentDay[`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Snack`]}
                                type={sectionPart}
                                day={day}
                                name={`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Snack`}
                                onUpdate={handleUpdate}
                            />
                        </Grid>
                        <Grid item xs={6} className="diet__mealsContainer">
                            <MealCard
                                title="Dinner"
                                icon={dinnerIcon}
                                items={currentDay[`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Dinner`]}
                                type={sectionPart}
                                day={day}
                                name={`${sectionPart}${day[0].toUpperCase() + day.substring(1)}Dinner`}
                                onUpdate={handleUpdate}
                            />
                        </Grid>
                    </Grid>
                </>
            );
    }

    return <></>;
};

const Diet: React.FC = () => {
    const hist = useHistory();
    const { currentParticipant } = useFetchUser();

    // States
    const [mainTabIndex, setMainTabIndex] = useState(0);
    const [TypicalWeekTabIndex, setTypicalWeekTabIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [content, setContent] = useState<File[]>([]);

    const fetchDietFiles = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.diet.files && currentParticipant.diet.files.length) {
            const localFiles: File[] = [];
            for (const c of currentParticipant.diet.files) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localFiles.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) localFiles.push(genDoc<File>()(payload));
                }
            }
            setFiles(localFiles);
        }
    };

    const fetchDietContent = async () => {
        if (currentParticipant && currentParticipant.id && currentParticipant.diet.content && currentParticipant.diet.content.length) {
            const localContent: File[] = [];
            for (const c of currentParticipant.diet.content) {
                let payload = await getDoc(doc(firestore, "Files", c));

                if (payload && payload.data()) localContent.push(genDoc<File>()(payload));
                else {
                    payload = await getDoc(doc(firestore, "Participants", currentParticipant.id, "Files", c));
                    if (payload) {
                        const privateContent = genDoc<File>()(payload);
                        privateContent.participantId = currentParticipant.id;
                        localContent.push(privateContent);
                    }
                }
            }
            setContent(localContent);
        }
    };

    useEffect(() => {
        if (currentParticipant) {
            fetchDietFiles();
            fetchDietContent();
        }
    }, [currentParticipant]);

    React.useEffect(() => {
        if (currentParticipant && currentParticipant.id) {
            // Participant log
            updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: "Visited diet page" }],
            });
        }
    }, []);

    return (
        <>
            <TopMenu title="Your personalized diet" icon={dietLogo} backButton />

            <div>
                <Grid item md={12} className="diet__container">
                    <Tabs
                        value={mainTabIndex}
                        onChange={(event, newValue) => setMainTabIndex(newValue)}
                        centered
                        className="diet__days"
                        textColor="inherit"
                    >
                        <Tab className="diet__days__week" label="Typical Week" {...a11yProps(0)} />
                        <Tab className="diet__days__week" label="Content" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={mainTabIndex} index={0}>
                        <Tabs
                            className="diet__days"
                            value={TypicalWeekTabIndex}
                            onChange={(event, newValue) => setTypicalWeekTabIndex(newValue)}
                            textColor="inherit"
                        >
                            <Tab className="diet__days__btn" label="Mon" {...a11yProps(0)} />
                            <Tab className="diet__days__btn" label="Tue" {...a11yProps(1)} />
                            <Tab className="diet__days__btn" label="Wed" {...a11yProps(2)} />
                            <Tab className="diet__days__btn" label="Thu" {...a11yProps(3)} />
                            <Tab className="diet__days__btn" label="Fri" {...a11yProps(4)} />
                            <Tab className="diet__days__btn" label="Sat" {...a11yProps(5)} />
                            <Tab className="diet__days__btn" label="Sun" {...a11yProps(6)} />
                        </Tabs>
                        <TabPanel value={TypicalWeekTabIndex} index={0}>
                            <DietSection section="typicalWeek" day="monday" />
                        </TabPanel>
                        <TabPanel value={TypicalWeekTabIndex} index={1}>
                            <DietSection section="typicalWeek" day="tuesday" />
                        </TabPanel>
                        <TabPanel value={TypicalWeekTabIndex} index={2}>
                            <DietSection section="typicalWeek" day="wednesday" />
                        </TabPanel>
                        <TabPanel value={TypicalWeekTabIndex} index={3}>
                            <DietSection section="typicalWeek" day="thursday" />
                        </TabPanel>
                        <TabPanel value={TypicalWeekTabIndex} index={4}>
                            <DietSection section="typicalWeek" day="friday" />
                        </TabPanel>
                        <TabPanel value={TypicalWeekTabIndex} index={5}>
                            <DietSection section="typicalWeek" day="saturday" />
                        </TabPanel>
                        <TabPanel value={TypicalWeekTabIndex} index={6}>
                            <DietSection section="typicalWeek" day="sunday" />
                        </TabPanel>
                    </TabPanel>
                    <TabPanel value={mainTabIndex} index={1}>
                        <DietSection section="recommendation" day="" />
                    </TabPanel>
                </Grid>
            </div>
            <div className="diet__middle">
                <div className="portalLayout__title">
                    <h4 className="home__title">Interactive content</h4>
                    <Grid container item>
                        {!content.length ? (
                            <div style={{ marginLeft: 5 }}>No media.</div>
                        ) : (
                            content.map((c, i) => (
                                <Grid key={i} item xs={12}>
                                    <MediaCard
                                        title={c.title}
                                        onClick={async () => {
                                            if (currentParticipant && currentParticipant.id) {
                                                // Private content
                                                if (c.participantId) {
                                                    hist.push(`videoViewer/${c.id}/${currentParticipant.id}`);
                                                }
                                                // Shared content
                                                else {
                                                    hist.push(`videoViewer/${c.id}/undefined`);
                                                }
                                                // Participant log
                                                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                    logs: [
                                                        ...(currentParticipant?.logs ?? []),
                                                        { date: new Date(), action: `Viewed diet video ${c.title} (${c.id})` },
                                                    ],
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                            ))
                        )}
                    </Grid>
                </div>
                <div className="portalLayout__title">
                    <h4 className="home__title">Personalized Instructions</h4>
                    <div className="sleep__files">
                        {!files.length ? (
                            <p style={{ marginLeft: 5 }}>No file.</p>
                        ) : (
                            files.map((f, i) => (
                                <FileCard
                                    key={i}
                                    title={f.title}
                                    icon={getIcon(f.extension)}
                                    onClick={async () => {
                                        if (currentParticipant && currentParticipant.id) {
                                            // Participant log
                                            await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                                                logs: [
                                                    ...(currentParticipant?.logs ?? []),
                                                    { date: new Date(), action: `Viewed diet file ${f.title} (${f.id})` },
                                                ],
                                            });
                                        }
                                        window.open(f.url, "_blank");
                                    }}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>
            <div className="home__buttons">
                <Grid item md={12} className="home__optionsContainer">
                    <Grid item xs={12} onClick={() => hist.push("/")} className="home__options__card">
                        <h4 className="home__options__title">Back to Home</h4>
                        <IoChevronBack className="home__options__image" />
                        <IoChevronBack className="home__options__background" />
                    </Grid>
                </Grid>
                <Grid item md={12} className="home__optionsContainer">
                    <Grid item xs={12} onClick={() => setOpen(true)} className="home__options__card">
                        <Button className="home__options__title">
                            <h4>Complete survey</h4>
                        </Button>
                        <RiFileList2Line className="home__options__image" />
                        <RiFileList2Line className="home__options__background" />
                    </Grid>
                </Grid>
            </div>

            {open && <SurveyDialog open={open} setOpen={setOpen} topic="Diet" />}
        </>
    );
};

export default Diet;
