import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";

// Firebase
import { firestore } from "../../firebase/firebase";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { genDoc } from "../../utils/firebase";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Interfaces
import { Participant } from "../../interfaces/Participant";

// yup validation
const requiredMessage = "Required field";

const bloodPressureRegex = /^\d{1,3}\/\d{1,3}$/;

const userSchema = yup.object({
    weight: yup.number().required(requiredMessage),
    weightUnit: yup.string().required(requiredMessage),
    bloodPressure: yup.string().required(requiredMessage).matches(bloodPressureRegex, "Blood pressure must look like '120/80'"),
    height1: yup.number().required(requiredMessage),
    height2: yup.number().required(requiredMessage),
    heightUnit1: yup.string().required(requiredMessage),
    heightUnit2: yup.string().required(requiredMessage),
    waistCircumference: yup.number().required(requiredMessage),
    waistUnit: yup.string().required(requiredMessage),
    hipCircumference: yup.number().required(requiredMessage),
    hipUnit: yup.string().required(requiredMessage),
    heartRate: yup.number().required(requiredMessage),
    cholesterol: yup.number().required(requiredMessage),
    glucose: yup.number().required(requiredMessage),
});

const defaultValues = {
    weight: 0,
    weightUnit: "kg",
    bloodPressure: 0,
    height1: 0,
    height2: 0,
    heightUnit1: "ft",
    heightUnit2: "cm",
    waistCircumference: 0,
    waistUnit: "cm",
    hipCircumference: 0,
    hipUnit: "cm",
    heartRate: 0,
    cholesterol: 0,
    glucose: 0,
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
    participantId?: string | undefined;
}

const BiometricDialog: React.FC<Props> = ({ open, setOpen, participantId }) => {
    const { currentParticipant, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [participant, setParticipant] = useState<Participant | undefined>(undefined);

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch,
    } = useForm({ resolver: yupResolver(userSchema), defaultValues });

    const weight = watch("weight");
    const weightUnit = watch("weightUnit");
    const height1 = watch("height1");
    const height2 = watch("height2");
    const heightUnit1 = watch("heightUnit1");
    const heightUnit2 = watch("heightUnit2");

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);

            if (participant && participant.id) {
                if (
                    !participant.biometrics.length ||
                    (participant.biometrics.length &&
                        !(
                            participant.biometrics[participant.biometrics.length - 1].weight === data.weight &&
                            participant.biometrics[participant.biometrics.length - 1].height === data.height &&
                            participant.biometrics[participant.biometrics.length - 1].waistCircumference === data.waistCircumference &&
                            participant.biometrics[participant.biometrics.length - 1].hipCircumference === data.hipCircumference &&
                            participant.biometrics[participant.biometrics.length - 1].bloodPressure === data.bloodPressure &&
                            participant.biometrics[participant.biometrics.length - 1].heartRate === data.heartRate &&
                            participant.biometrics[participant.biometrics.length - 1].cholesterol === data.cholesterol &&
                            participant.biometrics[participant.biometrics.length - 1].glucose === data.glucose
                        ))
                ) {
                    // Height
                    let height = 0;
                    if (data.heightUnit2 === "cm") height = data.height2;
                    else height = Math.round((data.height1 * 12 + data.height2) * 2.54);

                    await updateDoc(doc(firestore, "Participants", participant.id), {
                        biometrics: [
                            ...participant.biometrics,
                            {
                                date: new Date(),
                                weight: data.weightUnit === "kg" ? data.weight : Math.round(data.weight / 2.205),
                                height,
                                waistCircumference: data.waistUnit === "cm" ? data.waistCircumference : Math.round(data.waistCircumference * 2.54),
                                hipCircumference: data.hipUnit === "cm" ? data.hipCircumference : Math.round(data.hipCircumference * 2.54),
                                bloodPressure: data.bloodPressure,
                                heartRate: data.heartRate,
                                cholesterol: data.cholesterol,
                                glucose: data.glucose,
                            },
                        ],
                    });

                    if (participant && participant.id) {
                        // Participant log
                        await updateDoc(doc(firestore, "Participants", participant.id), {
                            logs: [...participant.logs, { date: new Date(), action: "Updated biometric data" }],
                        });
                    }

                    enqueueSnackbar("Updated biometrics", { variant: "success" });

                    setOpen(false);
                }
            }

            if (currentParticipant && currentParticipant.id) {
                if (
                    !currentParticipant.biometrics.length ||
                    (currentParticipant.biometrics.length &&
                        !(
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].weight === data.weight &&
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].height === data.height &&
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].waistCircumference === data.waistCircumference &&
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].hipCircumference === data.hipCircumference &&
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].bloodPressure === data.bloodPressure &&
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].heartRate === data.heartRate &&
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].cholesterol === data.cholesterol &&
                            currentParticipant.biometrics[currentParticipant.biometrics.length - 1].glucose === data.glucose
                        ))
                ) {
                    // Height
                    let height = 0;
                    if (data.heightUnit2 === "cm") height = data.height2;
                    else height = Math.round((data.height1 * 12 + data.height2) * 2.54);

                    await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                        biometrics: [
                            ...currentParticipant.biometrics,
                            {
                                date: new Date(),
                                weight: data.weightUnit === "kg" ? data.weight : Math.round(data.weight / 2.205),
                                height,
                                waistCircumference: data.waistUnit === "cm" ? data.waistCircumference : Math.round(data.waistCircumference * 2.54),
                                hipCircumference: data.hipUnit === "cm" ? data.hipCircumference : Math.round(data.hipCircumference * 2.54),
                                bloodPressure: data.bloodPressure,
                                heartRate: data.heartRate,
                                cholesterol: data.cholesterol,
                                glucose: data.glucose,
                            },
                        ],
                    });

                    if (currentParticipant && currentParticipant.id) {
                        // Participant log
                        await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                            logs: [...(currentParticipant?.logs ?? []), { date: new Date(), action: "Updated biometric data" }],
                        });
                    }

                    enqueueSnackbar("Updated biometrics", { variant: "success" });

                    setOpen(false);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const fetchParticipant = async () => {
        try {
            if (participantId) {
                const payload = await getDoc(doc(firestore, "Participants", participantId));
                setParticipant(genDoc<Participant>()(payload));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const bodyMassIndex = useMemo(() => {
        let localWeight = 0;
        let localHeight = 0;

        // weight
        if (weightUnit === "kg") {
            localWeight = parseFloat(weight as any);
        }
        if (weightUnit === "lb") {
            localWeight = parseFloat(weight as any) / 2.2;
        }
        // height
        if (heightUnit2 === "cm") {
            localHeight = parseFloat(height2 as any);
        }
        if (heightUnit2 === "in") {
            localHeight = (parseFloat(height1 as any) * 12 + parseFloat(height2 as any)) * 2.54;
        }

        return (localWeight / ((localHeight / 100) * (localHeight / 100))).toFixed(1);
    }, [weight, height1, height2, heightUnit1, heightUnit2]);

    useEffect(() => {
        if (
            currentParticipant &&
            currentParticipant.id &&
            currentParticipant.hasBiometricData &&
            currentParticipant.biometrics &&
            currentParticipant.biometrics.length
        ) {
            setValue("weight", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].weight);
            setValue("height2", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].height);
            setValue("waistCircumference", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].waistCircumference);
            setValue("hipCircumference", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].hipCircumference);
            setValue("bloodPressure", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].bloodPressure);
            setValue("heartRate", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].heartRate);
            setValue("cholesterol", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].cholesterol);
            setValue("glucose", currentParticipant.biometrics[currentParticipant.biometrics.length - 1].glucose);
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (participant && participant.id && participant.hasBiometricData && participant.biometrics && participant.biometrics.length) {
            setValue("weight", participant.biometrics[participant.biometrics.length - 1].weight);
            setValue("height2", participant.biometrics[participant.biometrics.length - 1].height);
            setValue("waistCircumference", participant.biometrics[participant.biometrics.length - 1].waistCircumference);
            setValue("hipCircumference", participant.biometrics[participant.biometrics.length - 1].hipCircumference);
            setValue("bloodPressure", participant.biometrics[participant.biometrics.length - 1].bloodPressure);
            setValue("heartRate", participant.biometrics[participant.biometrics.length - 1].heartRate);
            setValue("cholesterol", participant.biometrics[participant.biometrics.length - 1].cholesterol);
            setValue("glucose", participant.biometrics[participant.biometrics.length - 1].glucose);
        }
    }, [participant]);

    useEffect(() => {
        if (participantId) {
            fetchParticipant();
        }
    }, [participantId]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            className="biometricDialog"
        >
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle style={{ paddingBottom: 10, textAlign: "center" }}>My Biometric data</DialogTitle>
                <DialogContent style={{ fontFamily: "Roboto", paddingTop: 20 }}>
                    <Grid container item spacing={1}>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Weight: </div>
                        </Grid>
                        <Grid container item xs={8} spacing={1}>
                            <Grid item xs={9}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            helperText={errors.weight?.message}
                                            error={!!errors.weight?.message}
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                    name="weight"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    render={({ field }) => (
                                        <FormControl fullWidth variant="outlined" error={!!errors.weightUnit?.message}>
                                            {/* <InputLabel id="roles-id">Participant</InputLabel> */}
                                            <Select
                                                labelId="roles-id"
                                                {...field}
                                                style={{ color: "white" }}
                                            >
                                                <MenuItem value="kg">kg</MenuItem>
                                                <MenuItem value="lb">lb</MenuItem>
                                            </Select>
                                            {!!errors.weightUnit?.message && <FormHelperText>{errors.weightUnit?.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                    name="weightUnit"
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Height: </div>
                        </Grid>
                        <Grid container item xs={8} spacing={1}>
                            {heightUnit2 === "in" && (
                                <>
                                    <Grid item xs={3}>
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    helperText={errors.height1?.message}
                                                    error={!!errors.height1?.message}
                                                    fullWidth
                                                    {...field}
                                                />
                                            )}
                                            name="height1"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            render={({ field }) => (
                                                <FormControl fullWidth variant="outlined" error={!!errors.heightUnit1?.message}>
                                                    {/* <InputLabel id="roles-id">Participant</InputLabel> */}
                                                    <Select labelId="roles-id" {...field} style={{ color: "white" }}>
                                                        <MenuItem value="ft">ft</MenuItem>
                                                    </Select>
                                                    {!!errors.heightUnit1?.message && <FormHelperText>{errors.heightUnit1?.message}</FormHelperText>}
                                                </FormControl>
                                            )}
                                            name="heightUnit1"
                                            control={control}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={heightUnit2 === "in" ? 3 : 9}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            helperText={errors.height2?.message}
                                            error={!!errors.height2?.message}
                                            fullWidth
                                            {...field}
                                            InputProps={{ inputProps: { min: 0, max: 11 } }}
                                        />
                                    )}
                                    name="height2"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    render={({ field }) => (
                                        <FormControl fullWidth variant="outlined" error={!!errors.heightUnit2?.message}>
                                            {/* <InputLabel id="roles-id">Participant</InputLabel> */}
                                            <Select
                                                labelId="roles-id"
                                                {...field}
                                                style={{ color: "white" }}
                                            >
                                                <MenuItem value="cm">cm</MenuItem>
                                                <MenuItem value="in">in</MenuItem>
                                            </Select>
                                            {!!errors.heightUnit2?.message && <FormHelperText>{errors.heightUnit2?.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                    name="heightUnit2"
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Waist Circumference: </div>
                        </Grid>
                        <Grid container item xs={8} spacing={1}>
                            <Grid item xs={9}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            helperText={errors.waistCircumference?.message}
                                            error={!!errors.waistCircumference?.message}
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                    name="waistCircumference"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    render={({ field }) => (
                                        <FormControl fullWidth variant="outlined" error={!!errors.waistUnit?.message}>
                                            {/* <InputLabel id="roles-id">Participant</InputLabel> */}
                                            <Select
                                                labelId="roles-id"
                                                {...field}
                                                style={{ color: "white" }}
                                            >
                                                <MenuItem value="cm">cm</MenuItem>
                                                <MenuItem value="in">in</MenuItem>
                                            </Select>
                                            {!!errors.waistUnit?.message && <FormHelperText>{errors.waistUnit?.message}</FormHelperText>}
                                        </FormControl>
                                    )}
                                    name="waistUnit"
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Hip Circumference: </div>
                        </Grid>
                        <Grid container item xs={8} spacing={1}>
                            <Grid item xs={9}>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            helperText={errors.hipCircumference?.message}
                                            error={!!errors.hipCircumference?.message}
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                    name="hipCircumference"
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Controller
                                    render={({ field }) => (
                                        <FormControl fullWidth variant="outlined" error={!!errors.hipCircumference?.message}>
                                            {/* <InputLabel id="roles-id">Participant</InputLabel> */}
                                            <Select
                                                labelId="roles-id"
                                                {...field}
                                                style={{ color: "white" }}
                                            >
                                                <MenuItem value="cm">cm</MenuItem>
                                                <MenuItem value="in">in</MenuItem>
                                            </Select>
                                            {!!errors.hipCircumference?.message && (
                                                <FormHelperText>{errors.hipCircumference?.message}</FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                    name="hipUnit"
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Blood Pressure: </div>
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        helperText={errors.bloodPressure?.message}
                                        error={!!errors.bloodPressure?.message}
                                        fullWidth
                                        {...field}
                                        InputProps={{
                                            endAdornment: <div style={{ color: "white" }}> mmhg</div>,
                                        }}
                                    />
                                )}
                                name="bloodPressure"
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Heart Rate: </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            style={{ textAlign: "center" }}
                                            variant="outlined"
                                            type="number"
                                            helperText={errors.heartRate?.message}
                                            error={!!errors.heartRate?.message}
                                            fullWidth
                                            {...field}
                                            InputProps={{
                                                endAdornment: <div style={{ color: "white" }}> bpm</div>,
                                            }}
                                        />
                                    )}
                                    name="heartRate"
                                    control={control}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Cholesterol: </div>
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        helperText={errors.cholesterol?.message}
                                        error={!!errors.cholesterol?.message}
                                        fullWidth
                                        {...field}
                                        InputProps={{
                                            endAdornment: <div style={{ color: "white" }}> mmol/L</div>,
                                        }}
                                    />
                                )}
                                name="cholesterol"
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Glucose: </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            style={{ textAlign: "center" }}
                                            variant="outlined"
                                            type="number"
                                            helperText={errors.glucose?.message}
                                            error={!!errors.glucose?.message}
                                            fullWidth
                                            {...field}
                                            InputProps={{
                                                endAdornment: <div style={{ color: "white" }}> mmol/L</div>,
                                            }}
                                        />
                                    )}
                                    name="glucose"
                                    control={control}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ fontSize: 15, marginTop: 18 }}>
                            <div className="biometric-label">Body Mass Index: </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div style={{ paddingLeft: 10, color: "white", marginTop: 19 }}>{bodyMassIndex}</div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className="cta__submit" variant="contained" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                    <Button className="cta__blue" id="submitButton" type="submit" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default BiometricDialog;
