import React from "react";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";

// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

// Hooks
import { useFetchUser } from "../../hooks/useFetchUser";

// Firebase
import { firestore } from "../../firebase/firebase";
import { updateDoc, doc } from "firebase/firestore";
import SleepDiary from "../pages/portal/questionnaires/SleepDiary";

// Custom components
import Title from "./Title";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
    selectedDate: Moment | null;
    events: any[];
}

const SleepDialog: React.FC<Props> = ({ open, setOpen, selectedDate, events }) => {
    const { currentParticipant } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    const handleComplete = async (data: any, score: number) => {
        if (selectedDate) {
            if (currentParticipant && currentParticipant.id) {
                const newEvent = {
                    date: selectedDate.toDate(),
                    value: data,
                    score,
                };
                updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                    sleep: {
                        ...currentParticipant.sleep,
                        dates: [...events, newEvent],
                        files: [...currentParticipant.sleep.files],
                        content: [...currentParticipant.sleep.content],
                    },
                });

                // Participant log
                await updateDoc(doc(firestore, "Participants", currentParticipant.id), {
                    logs: [
                        ...(currentParticipant?.logs ?? []),
                        { date: new Date(), action: `Answered ${moment(selectedDate).format("DD/MM/YYYY")} sleep diary` },
                    ],
                });

                // setEvents([...events, newEvent]);
            }
        }

        enqueueSnackbar("questionnaire completed", { variant: "success" });

        setOpen(false);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            className="sleepDialog"
        >
            <DialogTitle>
                <Title>Sleep Diary - {moment(selectedDate).format("DD/MM/YYYY")}</Title>
            </DialogTitle>
            <DialogContent style={{ fontFamily: "Roboto" }}>
                <SleepDiary onComplete={handleComplete} setOpen={setOpen} />
            </DialogContent>
        </Dialog>
    );
};

export default SleepDialog;
