import React from "react";

// Mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    // eslint-disable-next-line
    setOpen(value: boolean): void;
    types: string[];
    text: string | React.ReactElement;
    img: string;
}

// eslint-disable-next-line
const InstructionDialog: React.FC<Props> = ({ open, setOpen, types, text, img }) => {
    return (
        <Dialog
            className="instructionDialog"
            open={open}
            TransitionComponent={Transition}
            fullScreen
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle className="instruction__title">Instructions</DialogTitle>
            <DialogContent className="instruction__content">
                {text && <p className="instruction__text">{text}</p>}
                {img && (
                    <div className="centered__parent">
                        <img src={img} alt="img" className="nofilterimg" />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button fullWidth className="cta__submit" variant="contained" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InstructionDialog;
